import ColorPicker2 from './CustomWidget/ColorPicker2.vue'
import Title from './CustomWidget/Title.vue'
import ImageRadio from './CustomWidget/ImageRadio.vue'
import ImageRadio2 from './CustomWidget/ImageRadio2.vue'
import PageSelect from './CustomWidget/PageSelect.vue'

import formCreate from '@form-create/element-ui'
import { App } from 'vue'

const install = (app: App) => {
  formCreate.component('ColorPicker2', ColorPicker2)
  formCreate.component('GroupTitle', Title)
  formCreate.component('ImageRadio', ImageRadio)
  formCreate.component('ImageRadio2', ImageRadio2)
  formCreate.component('PageSelect', PageSelect)
  app.use(formCreate as any)
}

export default {
  install,
}
