<template>
  <div class="page-select">
    <el-container class="h-full">
      <el-aside width="130px" class="px-10px py-12px">
        <div
          v-for="(item, index) in pageList"
          :key="'left_' + item.type"
          :class="{ active: currentIndex === index }"
          class="type-item"
          @click.stop="toContent(index)"
        >
          {{ item.name }}
        </div>
      </el-aside>
      <el-main ref="rootRef" @scroll="scrollEvent">
        <div
          v-for="(item, index) in pageList"
          :key="'right_' + item.type"
          class="mb-10px page-group"
          :data-index="index"
        >
          <div class="type-title">{{ item.name }}</div>
          <el-button
            v-for="iitem in item.children"
            :key="iitem.path"
            :class="{ active: iitem.path === selectPath }"
            class="mb-10px"
            @click="choiceItem(iitem)"
          >
            {{ iitem.name }}
          </el-button>
        </div></el-main
      >
    </el-container>
  </div>
</template>
<script lang="ts" setup>
import { throttle } from 'lodash-es'
const currentIndex = ref(0)
const props = withDefaults(defineProps<{ selectPath: string }>(), {
  selectPath: '',
})
const pageList = ref([
  {
    type: 'coupon',
    name: '优惠券',
    children: [
      {
        name: '领券中心',
        path: '/pages/coupon/center',
      },
      {
        name: '我的优惠券',
        path: '/pages/coupon/index',
        component: '',
      },
    ],
  },
  // {
  //   type: 'goods',
  //   name: '商品',
  //   children: [
  //     {
  //       name: '普通商品',
  //       path: '',
  //     },
  //     {
  //       name: '蛋糕商品',
  //       path: '',
  //     },
  //     {
  //       name: '拼团商品',
  //       path: '',
  //     },
  //     {
  //       name: '秒杀商品',
  //       path: '',
  //     },
  //     {
  //       name: '商品列表',
  //       path: '',
  //     },
  //   ],
  // },
  {
    type: 'mall',
    name: '商城',
    children: [
      {
        name: '首页',
        path: '/pages/index/index',
      },
      {
        name: '个人中心',
        path: '/pages/user/index',
      },
      {
        name: '我的订单',
        path: '/pages/order/index',
      },
      {
        name: '自提点单',
        path: '/pages/category/pickup?type=1',
      },
      {
        name: '配送点单',
        path: '/pages/category/pickup?type=2',
      },
      {
        name: '蛋糕订单',
        path: '/pages/cake/index',
      },
      {
        name: '快递点单',
        path: '/pages/category/delivery',
      },
    ],
  },
  {
    type: 'application',
    name: '应用功能',
    children: [
      {
        name: '客服',
        path: 'openType:contact',
      },
      {
        name: '分享',
        path: 'openType:share',
      },
    ],
  },
  {
    type: 'activity',
    name: '营销活动',
    children: [
      // {
      //   name: '拼团活动',
      //   path: '/pages/marketing/group',
      // },
      {
        name: '秒杀活动',
        path: '/pages/marketing/flash',
      },
      {
        name: '积分商城',
        path: '/pages/point/mall',
      },
    ],
  },
  {
    type: 'user',
    name: '用户中心',
    children: [
      {
        name: '个人中心',
        path: '/pages/user/index',
      },
      {
        name: '地址管理',
        path: '/pages/address/index',
      },
      {
        name: '资料编辑',
        path: '/pages/user/edit',
      },
    ],
  },
  {
    type: 'account',
    name: '账户中心',
    children: [
      {
        name: '账户中心',
        path: '/pages/account/index',
      },
      {
        name: '账户流水',
        path: '/pages/account/log',
      },
      {
        name: '积分流水',
        path: '/pages/point/log',
      },
      {
        name: '会员二维码',
        path: '/pages/account/code',
      },
      {
        name: '现金充值',
        path: '/pages/account/recharge',
      },
      {
        name: '兑换券充值',
        path: '/pages/account/recharge?type=1',
      },
    ],
  },
])
const emit = defineEmits<{
  (e: 'change', path: string): void
}>()
const stop = ref(false)
const toContent = (index: number) => {
  currentIndex.value = index
  //获取滚动位置
  const allPages = document.querySelectorAll('.page-group')
  //停止滚动监控
  stop.value = true
  allPages[index].scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
  setTimeout(() => {
    stop.value = false
  }, 500)
}
const rootRef = ref()
const rightHeightSumArr: number[] = []
const getContentHeight = () => {
  const allPages = document.querySelectorAll('.page-group')
  let num = 0
  allPages.forEach((item) => {
    const offsetHeight = (item as HTMLElement).offsetHeight
    num = num + offsetHeight
    rightHeightSumArr.push(num)
  })
}

//监听内容页滚动
const scrollEvent = (e: UIEvent) => {
  console.log('stop: ', stop.value)

  if (stop.value) {
    return
  }
  const currentTarget = e.currentTarget as Element
  const scrollTop = currentTarget?.scrollTop ?? 0
  scrollFn(scrollTop)
}

const scrollFn = throttle((scrollTop: number) => {
  // 看看浏览器滚动的高度落到那个区间，在那个区间，就让对应的项高亮
  const scrollWhichIndex = rightHeightSumArr.findIndex((item, index) => {
    return scrollTop >= rightHeightSumArr[index] && scrollTop < rightHeightSumArr[index + 1]
  })
  // 初始的区间为-1，所以还让其为第一项，即索引为0，当用户往下滑动的时候，所以就会
  // 一直大于负一，所以就让其加上一和左侧的高亮项对应。
  if (scrollWhichIndex > -1) {
    currentIndex.value = scrollWhichIndex + 1
  } else {
    currentIndex.value = 0
  }
}, 500)

const choiceItem = (item: { path: string }) => {
  emit('change', item.path)
}

onMounted(() => {
  nextTick(() => {
    getContentHeight()
  })
})
</script>
<style lang="scss" scoped>
.page-select {
  height: 450px;
  ::v-deep(.el-aside) {
    border-right: 1px solid var(--el-border-color);
  }
  .type-item {
    padding: 4px 16px;
    margin-bottom: 8px;
    &.active {
      background-color: var(--el-color-primary-light-7);
      color: var(--el-color-primary);
      border-radius: 8px;
    }
  }
  .type-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .page-group {
    .active {
      color: var(--el-color-primary);
      border-color: var(--el-color-primary);
    }
  }
}
</style>
